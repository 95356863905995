<template>
  <v-layout row wrap>
    <v-flex v-if="updateAddress === false" xs12>
      <v-card class="primary--border" outlined>
        <v-container class="user-address" grid-list-md>
          <a
            v-if="$auth.can('student-update')"
            @click.prevent="updateAddress = true"
            class="edit-address"
            href=""
          >
            <v-icon>edit</v-icon>
          </a>

          <v-layout row wrap>
            <v-flex xs12>
              <strong style="margin-bottom: -4px"> Permanent Address</strong>
            </v-flex>
            <v-flex xs12 sm2>
              <small>Province/State</small>
              <p class="add">
                {{
                  $address.getName(
                    "state",
                    student.addressData.permanent.state_id
                  )
                }}
              </p>
            </v-flex>
            <v-flex xs12 sm3 md3>
              <small>District</small>
              <p class="add">
                {{
                  $address.getName(
                    "district",
                    student.addressData.permanent.district_id
                  )
                }}
              </p>
            </v-flex>
            <v-flex xs12 sm3 md3>
              <small>Municipality/City</small>
              <p class="add">
                {{
                  $address.getName(
                    "city",
                    student.addressData.permanent.city_id
                  )
                }}
              </p>
            </v-flex>
            <v-flex xs12 sm1>
              <small>Ward</small>
              <p class="add">{{ student.addressData.permanent.ward_no }}</p>
            </v-flex>
            <v-flex xs12 sm3 md3>
              <small>Street/Tole</small>
              <p class="add">{{ student.addressData.permanent.street }}</p>
            </v-flex>

            <v-flex xs12>
              <strong style="margin-bottom: -5px"> Current Address</strong>
            </v-flex>
            <v-flex xs12 sm2>
              <small>Province/State</small>
              <p class="add">
                {{
                  $address.getName(
                    "state",
                    student.addressData.temporary.state_id
                  )
                }}
              </p>
            </v-flex>
            <v-flex xs12 sm3 md3>
              <small>District</small>
              <p class="add">
                {{
                  $address.getName(
                    "district",
                    student.addressData.temporary.district_id
                  )
                }}
              </p>
            </v-flex>
            <v-flex xs12 sm3 md3>
              <small>Municipality/City</small>
              <p class="add">
                {{
                  $address.getName(
                    "city",
                    student.addressData.temporary.city_id
                  )
                }}
              </p>
            </v-flex>
            <v-flex xs12 sm1>
              <small>Ward</small>
              <p class="add">{{ student.addressData.temporary.ward_no }}</p>
            </v-flex>
            <v-flex xs12 sm3 md3>
              <small>Street/Tole</small>
              <p class="add">{{ student.addressData.temporary.street }}</p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-flex>

    <v-card v-if="updateAddress === true" class="pa-4">
      <v-form
        v-model="form.addressInfo.validation.valid"
        ref="addressInfoForm"
        lazy-validation
      >
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12>
              <strong style="margin-bottom: -5px"> Permanent Address</strong>
            </v-flex>
            <v-flex xs12 sm2>
              <v-autocomplete
                outlined
                dense
                :items="states"
                v-model="student.addressData.permanent.state_id"
                label="Province/State"
                data-vv-name="select"
                :rules="form.addressInfo.validation.permanent_state_id_rule"
                required
              />
            </v-flex>
            <v-flex xs12 sm3 md3>
              <v-autocomplete
                outlined
                dense
                :items="permanent_districts"
                v-model="student.addressData.permanent.district_id"
                label="District"
                data-vv-name="select"
                :rules="form.addressInfo.validation.permanent_district_id_rule"
                required
              />
            </v-flex>
            <v-flex xs12 sm3 md3>
              <v-autocomplete
                outlined
                dense
                :items="permanent_cities"
                v-model="student.addressData.permanent.city_id"
                label="Municipality/City"
                data-vv-name="select"
                :rules="form.addressInfo.validation.permanent_city_id_rule"
                required
              />
            </v-flex>
            <v-flex xs12 sm1>
              <v-autocomplete
                outlined
                dense
                :items="ward_count_permanent"
                :rules="form.addressInfo.validation.permanent_ward_no"
                v-model="student.addressData.permanent.ward_no"
                label="Ward"
                data-vv-name="select"
                required
              />
            </v-flex>
            <v-flex xs12 sm3 md3>
              <v-text-field
                label="Street/Tole"
                outlined
                dense
                v-model="student.addressData.permanent.street"
                :rules="form.addressInfo.validation.permanent_address_line_rule"
              />
            </v-flex>
            <br />
            <v-flex xs12 sm12 md12
              ><strong style="margin-bottom: -5px"
                >Current Address</strong
              ></v-flex
            >

            <v-flex xs12>
              <v-checkbox
                label="Mark current address same as permanent"
                v-model="same_address"
              />
            </v-flex>
            <v-flex xs12 sm2>
              <v-autocomplete
                outlined
                dense
                :items="states"
                v-model="student.addressData.temporary.state_id"
                label="Province/State"
                data-vv-name="select"
                :rules="form.addressInfo.validation.temporary_state_id_rule"
                required
              />
            </v-flex>
            <v-flex xs12 sm3 md3>
              <v-autocomplete
                outlined
                dense
                :items="temporary_districts"
                v-model="student.addressData.temporary.district_id"
                label="District"
                data-vv-name="select"
                :rules="form.addressInfo.validation.temporary_district_id_rule"
                required
              />
            </v-flex>
            <v-flex xs12 sm3 md3>
              <v-autocomplete
                outlined
                dense
                :items="temporary_cities"
                v-model="student.addressData.temporary.city_id"
                label="Municipality/City"
                data-vv-name="select"
                :rules="form.addressInfo.validation.temporary_city_id_rule"
                required
              />
            </v-flex>

            <v-flex xs12 sm1>
              <v-autocomplete
                outlined
                dense
                :items="ward_count_temporary"
                :rules="form.addressInfo.validation.temporary_ward_no"
                v-model="student.addressData.temporary.ward_no"
                label="Ward"
                data-vv-name="select"
                required
              />
            </v-flex>
            <v-flex xs12 sm3 md3>
              <v-text-field
                label="Street/Tole"
                outlined
                dense
                v-model="student.addressData.temporary.street"
                :rules="form.addressInfo.validation.temporary_address_line_rule"
              />
            </v-flex>
          </v-layout>
        </v-container>
        <v-btn @click.prevent="updateAddress = false">Cancel</v-btn>
        <v-btn
          color="primary"
          @click.native="submitAddressInfoForm"
          :disabled="!form.addressInfo.validation.valid"
          >Update Address
        </v-btn>
      </v-form>
    </v-card>
    <!--</v-flex>-->
  </v-layout>
</template>
<script>
import Form from "@/library/Form";
import { mapState, mapActions, mapMutations } from "vuex";
import AddressHelper from "@/library/AddressHelper";

const NepaliDate = require("nepali-date-js");
import statesData from "@/assets/json/states.json";
import districtDataData from "@/assets/json/districts.json";
import cityData from "@/assets/json/cities.json";

export default {
  data: () => ({
    updateAddress: false,
    form: {
      addressInfo: {
        data: {},
        validation: {
          valid: false,
          permanent_state_id_rule: [(v) => !!v || "Please select state!"],
          temporary_state_id_rule: [(v) => !!v || "Please select state!"],
          permanent_address_line_rule: [(v) => !!v || "Address is required!"],
          temporary_address_line_rule: [(v) => !!v || "Address is required!"],
          permanent_district_id_rule: [(v) => !!v || "Please select district!"],
          temporary_district_id_rule: [(v) => !!v || "Please select district!"],
          permanent_city_id_rule: [
            (v) => !!v || "Please select municipality/city!",
          ],
          temporary_city_id_rule: [
            (v) => !!v || "Please select municipality/city!",
          ],
        },
      },
      familyInfo: {
        data: {},
        validation: {
          valid: true,
        },
      },
      enrollInfo: {
        data: {},
        validation: {
          valid: false,
          base_idRule: [(v) => !!v || "Please select level!"],
          grade_idRule: [(v) => !!v || "Please select class!"],
          section_idRule: [(v) => !!v || "Please select section!"],
        },
      },
      error: null,
    },
    statesData,
    districtDataData,
    cityData,
    states: [],
    permanent_districts: [],
    temporary_districts: [],
    permanent_cities: [],
    temporary_cities: [],
    ward_count_permanent: [],
    ward_count_temporary: [],
    same_address: false,
  }),
  watch: {
    "student.addressData.permanent.state_id": {
      handler(v) {
        this.permanent_districts = [];
        this.permanent_cities = [];
        this.ward_count_permanent = [];
        this.getDistricts("permanent", v);
      },
    },
    "student.addressData.permanent.district_id": {
      handler(v) {
        this.ward_count_permanent = [];
        this.permanent_cities = [];
        this.getCities("permament");
      },
    },
    "student.addressData.permanent.city_id": {
      handler(v) {
        this.ward_count_permanent = [];
        this.getWardCount("permanent");
      },
    },
    "student.addressData.permanent.address": {
      handler(name) {
        this.student.addressData.permanent.address = this.capitalizeString(
          name
        );
      },
    },
    "student.addressData.temporary.address": {
      handler(name) {
        this.student.addressData.temporary.address = this.capitalizeString(
          name
        );
      },
    },
    "student.addressData.temporary.state_id": {
      handler(v) {
        this.temporary_districts = [];
        this.temporary_cities = [];
        this.ward_count_temporary = [];
        this.getTemporaryDistricts("temporary", v);
      },
    },
    "student.addressData.temporary.district_id": {
      handler(v) {
        this.ward_count_temporary = [];
        this.temporary_cities = [];
        this.getTemporaryCities("temporary", v);
      },
    },
    "student.addressData.temporary.city_id": {
      handler() {
        this.ward_count_temporary = [];
        this.getWardCount("temporary");
      },
    },
    "student.addressData": function() {
      if (Object.keys(this.student.addressData).length > 0) {
        this.getDistricts();
        this.getTemporaryCities();
        this.getTemporaryDistricts();
        this.getCities();
        this.getWardCount("temporary");
        this.getWardCount("permanent");
      }
    },
    same_address: {
      handler(v) {
        this.copyAddress(v);
      },
    },
  },
  mounted() {
    this.getStates();
  },
  computed: {
    ...mapState(["student"]),
  },
  methods: {
    getStates(type) {
      this.states = this.statesData.map((item) => {
        return { text: item.name, value: item.id };
      });
    },
    getDistricts(type, id) {
      this.permanent_districts = this.districtDataData
        .filter(
          (item) =>
            item.state_id === this.student.addressData.permanent.state_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
    },
    getTemporaryDistricts() {
      this.temporary_districts = this.districtDataData
        .filter(
          (item) =>
            item.state_id === this.student.addressData.temporary.state_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
    },

    getCities() {
      this.permanent_cities = this.cityData
        .filter(
          (item) =>
            item.district_id === this.student.addressData.permanent.district_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
    },

    getTemporaryCities() {
      this.temporary_cities = this.cityData
        .filter(
          (item) =>
            item.district_id === this.student.addressData.temporary.district_id
        )
        .map((item) => {
          return { value: item.id, text: item.name };
        });
    },
    copyAddress(status) {
      if (status) {
        if (
          this.student.addressData.permanent.street &&
          this.student.addressData.permanent.city_id
        ) {
          let temp = {
            state: this.student.addressData.permanent.state_id,
            district: this.student.addressData.permanent.district_id,
            city: this.student.addressData.permanent.city_id,
            street: this.student.addressData.permanent.street,
            ward_no: this.student.addressData.permanent.ward_no,
          };
          let data = JSON.parse(JSON.stringify(temp));

          this.$set(this.student.addressData.temporary, "state_id", data.state);
          this.$set(
            this.student.addressData.temporary,
            "district_id",
            data.district
          );
          this.$set(this.student.addressData.temporary, "city_id", data.city);
          this.$set(this.student.addressData.temporary, "street", data.street);
          this.$set(
            this.student.addressData.temporary,
            "ward_no",
            data.ward_no
          );
        }
      }
    },
    getWardCount(type) {
      let $this = this;
      let totalWards = [];
      if (type === "permanent") {
        let city = this.cityData.filter(function(city, key) {
          return $this.student.addressData.permanent.city_id === city.id;
        });
        this.ward_count_permanent = [];
        if (city.length) {
          city = city[0];
          let totalNumberOfWards = parseInt(city.ward_count);
          for (let i = 1; i <= totalNumberOfWards; i++) {
            totalWards.push(i);
          }
        }
        this.ward_count_permanent = totalWards;
      } else {
        let city = this.cityData.filter(function(city, key) {
          return $this.student.addressData.temporary.city_id === city.id;
        });
        this.ward_count_temporary = [];
        if (city.length) {
          city = city[0];
          let totalNumberOfWards = parseInt(city.ward_count);
          for (let i = 1; i <= totalNumberOfWards; i++) {
            totalWards.push(i);
          }
        }
        this.ward_count_temporary = totalWards;
      }
    },
    submitAddressInfoForm() {
      let formData = {};
      formData.pcity_id = this.student.addressData.permanent.city_id;
      formData.pstreet = this.student.addressData.permanent.street;
      formData.pward_no = this.student.addressData.permanent.ward_no;
      formData.tdistrict_id = this.student.addressData.temporary.district_id;
      formData.tstate_id = this.student.addressData.temporary.state_id;
      formData.pdistrict_id = this.student.addressData.permanent.district_id;
      formData.pstate_id = this.student.addressData.permanent.state_id;
      formData.tcity_id = this.student.addressData.temporary.city_id;
      formData.tstreet = this.student.addressData.temporary.street;
      formData.tward_no = this.student.addressData.temporary.ward_no;
      this.$rest
        .put(`api/student/${this.$route.params.id}/address`, formData)
        .then((res) => {
          this.$events.fire("notification", { message: res.data.message });
          this.updateAddress = false;
        });
    },
    capitalizeString(string) {
      if (string === undefined || string === null) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style type="scss" scoped>
/*.user-address {*/
p.add {
  font-size: 15px !important;
}

.edit-address {
  border-radius: 30px;
  padding-right: 30px;
  font-size: 12px;
  text-decoration: none;
  display: block;
  position: absolute;
  right: 0;
}

/*}*/
</style>
